<style scoped lang = 'less'>
.index{
    width: 100%;

    text-align: center;

}
.mu-demo-form{
    display: flex;
    justify-content: center;
    .mu-form-item{
        min-width: 200px;
        margin: 0 15px
    }
}
.box{
    width:800px;
    margin:0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.innerBox{
    width:200px;
    padding-top:100%;
    height:0;
    cursor:pointer;
    border:5px solid transparent;
    position: relative;
}
.colorBox{
    position: absolute;
    background:#ccc;
    height:100%;
    width:100%;
    top:0;
    left:0;
    border-radius: 10px;
    transition: all 0.3s;


}
.colorBox.active{
    background-color: #3399ff
}
</style>
<template>
    <div class="index">
        <mu-form  class="mu-demo-form" :model = 'postForm' label-position="left" label-width="100">
            <mu-form-item prop="num" label="关卡">
                <mu-text-field v-model.number="postForm.num" type= 'number' :max = '10' :min = '2' @change = 'initbox'></mu-text-field>
            </mu-form-item>
            <mu-form-item prop="width" label="宽度">
                <mu-text-field v-model.number="postForm.width" type= 'number'  :min = '100' @change = 'initbox'></mu-text-field>
            </mu-form-item>
        </mu-form>


        <div class="box" :style = "{width:postForm.width+'px'}" >
            <div class = 'innerBox' v-for = '(key,i) in arr'   :style = '{width:"calc(100% / "+postForm.num+")",paddingTop:"calc(100% / "+postForm.num+" - 10px)" }' :key = '"liu"+key+i'>
                <div  class = 'colorBox' @click = 'click($event,i,key)' :style = '{background:key?"#3399ff":"#ccc"}'>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data:function(){
        return {
            postForm:{
                num:2,
                width:800,
            },
            arr:[]
        }
    },
    computed:{
        totle:function (){
            return (this.postForm.num +1) * (this.postForm.num+1)
        },

    },
    mounted(){
        this.initbox()
    },
    methods: {
        click(e,i,key){
            this.$set(this.arr,i,!this.arr[i])

            if(this.arr[i-this.postForm.num]!==undefined){
                this.$set(this.arr,i-this.postForm.num,!this.arr[i-this.postForm.num])
            }
            if(this.arr[i+this.postForm.num]!==undefined){
                this.$set(this.arr,i+this.postForm.num,!this.arr[i+this.postForm.num])
            }
            if((i % this.postForm.num ==0)||((i+1) % this.postForm.num ==0)){
                if(i % this.postForm.num ==0){
                    this.$set(this.arr,i+1,!this.arr[i+1])
                }
                if((i+1) % this.postForm.num ==0){
                    this.$set(this.arr,i-1,!this.arr[i-1])
                }
            } else{
                this.$set(this.arr,i+1,!this.arr[i+1])
                this.$set(this.arr,i-1,!this.arr[i-1])

            }
            var flag = 0
            for(var i = 0;i<this.arr.length;i++){
                if(!this.arr[i]){
                    flag++
                }
            }
            if(!flag){
                this.postForm.num = this.postForm.num+1
                this.initbox()
            }
                        },
        initbox(){
            var a = this.postForm.num  * this.postForm.num
            var arr = []
            for(var i = 0;i<a;i++){
                arr.push(false)
            }
            this.arr = arr

        }
    }
}
</script>
